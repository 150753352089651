import {WordWithBlob} from "./WordWithBlob";




export const wordsAreSimilar = (tested: WordWithBlob, toCompare: WordWithBlob[]): boolean => {
    return toCompare.some(x => wordsStringsSimilar(x.string, tested.string))
}

const wordsStringsSimilar = (a: string, b: string): boolean => {
    const range = Math.min(a.length, b.length) / 2
    const beginning = (str: string) => str.slice(0, range)
    // .replace('ä', 'a') narazie tylko uk jest kwadracikowany
    // .replace('ü', 'u')
    // .replace('ö', 'o')

    return beginning(a) === beginning(b)
}


