import {PairWithStats, Topic} from "./shared-modules/materialTypes";
import {Scene} from "./Scene";

export type SceneTopic = Topic<Scene>


export function makeShortListOfPairs(pairs: PairWithStats[]): PairWithStats[] {
    const shortList: PairWithStats[] = []
    for (const p of pairs) {
        if (p.weight > 0 || shortList.length < 7) {
            shortList.push(p)
        }
    }
    return shortList
}