import {PairOfRichWords, RichWord} from "./shared-modules/materialTypes";

export function isSimilarToOneOf(tested: PairOfRichWords, others: PairOfRichWords[]): boolean {
    for (const testedAgainst of others) {
        if (pairsAreConflicted(tested, testedAgainst)) {
            return true
        }
    }
    return false
}

export function pairsAreConflicted<T extends PairOfRichWords>(tested: T, other: T): boolean {
    return wordsAreConflicted(tested.shownWord, other.shownWord) ||
        wordsAreConflicted(tested.goodAnswer, other.goodAnswer)
}

function wordsAreConflicted(w1: RichWord, w2: RichWord): boolean {
    return w1.string === w2.string ||
        (w1.picFilename !== undefined) && (w1.picFilename === w2.picFilename)
}