import {
    APICommand,
    commandApiEntryPoint, DTOdata,
    LogLvl,
    parseResponse, PartialLogRecord,
    ResponseDTO,
} from "./shared-modules/commandApi";
import {SAFESON} from "./shared-modules/funkcje";


export function sendFakeShot(message: string) {
    const data: PartialLogRecord = {
        level: "NOTICE",
        loggerName: "browser",
        message,
        screenshotData: "",
    }
    return request(APICommand.Log, data)
}


export function sendLogToServer(level: LogLvl, message: string) {
    const data: PartialLogRecord = {
        level,
        loggerName: "browser",
        message,
        screenshotData: "",
    }
    return request(APICommand.Log, data)
}


export async function request(command: APICommand, data: DTOdata): Promise<ResponseDTO<DTOdata>> {
    const response = await fetch(commandApiEntryPoint, {
        method: "POST",
        body: SAFESON.stringify({userName: "", command, data}),
        headers: {'Content-Type': "application/json",}
    })
    return parseResponse(response)
}


