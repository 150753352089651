export class AudioObject {

    private readonly audio: HTMLAudioElement

    constructor(blob: Blob) {
        this.audio = new Audio(URL.createObjectURL(blob))
    }

    async play() {
        this.audio.currentTime = 0
        await this.audio.play()
    }


    pause() {
        this.audio.pause()
    }


}