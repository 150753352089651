import {useState} from "react";
import {ProgressBar} from "./ProgressBar";
import {LessonSummaryView} from "./LessonSummaryView";
import {H1, Vertically} from "./StyledComponents";
import {TopicView} from "./TopicView";
import {firstEl} from "./shared-modules/funkcje";
import {request} from "./Requests";
import {APICommand} from "./shared-modules/commandApi";
import {User} from "./shared-modules/sharedTypes";
import {PastAnswer} from "./shared-modules/materialTypes";
import {SceneTopic} from "./SceneTopic";

type ViewName = "stage" | "openingInternet" | "summary"


type State = {
    readonly started: number
    readonly view: ViewName
    readonly mainScore: number
    readonly topics: SceneTopic[]
}

export function LearningView({user, topics}: { user: User, topics: SceneTopic[] }) {

    const [state, setState] = useState(buildStateFirstTime(topics))

    function numberItemsToLearn() {
        return topics.flatMap(t => t.pairs).length
    }


    async function giveNextTopic(learnedTopic: SceneTopic, accuracy: number, scoreEarned: number): Promise<void> {
        const newMainScore = state.mainScore + scoreEarned

        if (newMainScore > numberItemsToLearn() * 0.8) {
            console.log("otwieram internet przed czasem")
            request(APICommand.OpenInternet, null).then()
        }

        const oldTopics = state.topics
        let newTopics: SceneTopic[]
        if (accuracy < 0.25) {
            newTopics = [...oldTopics.slice(1,), learnedTopic]
        } else {
            newTopics = [...oldTopics.slice(1, 2), learnedTopic, ...oldTopics.slice(2,)]
        }


        if (newMainScore >= numberItemsToLearn()) {
            setState({...state, view: "openingInternet"})
            await request(APICommand.Update, topicsToPastAnswers(newTopics))
            setState({...state, view: "summary"})
        } else {
            setState({...state, topics: newTopics, mainScore: newMainScore})
        }
    }


    function topicsToPastAnswers(topics: SceneTopic[]): PastAnswer[] {
        const pastAnswers: PastAnswer[] = []
        for (const topic of topics) {
            for (const scene of topic.pairs) {
                pastAnswers.push({key: scene.key, hits: scene.hits, errors: scene.errors})
            }
        }
        return pastAnswers
    }


    return (
        <>
            {
                state.view === "stage" &&
                <Vertically>
                    <ProgressBar length={state.mainScore / numberItemsToLearn()} color={'blue'}/>
                    <TopicView topic={firstEl(state.topics)} user={user} nextTopic={giveNextTopic}/>
                </Vertically>
            }
            {
                state.view === "openingInternet" &&
                <H1>Opening internet. Please wait...</H1>
            }
            {
                state.view === "summary" &&
                <LessonSummaryView started={state.started}
                                   topics={state.topics}
                                   user={user}/>
            }
        </>
    )
}


function buildStateFirstTime(topics: SceneTopic[]): State {
    return {
        started: Date.now(),
        view: "stage",
        mainScore: 0,
        topics,
    }
}




