import {useEffect, useState} from "react";
import {Button, H2, H3, Vertically} from "./StyledComponents";
import {RankingRecord, User} from "./shared-modules/sharedTypes";
import {LearningView} from "./LearningView";
import {EnglishDoneWriting} from "./EnglishDoneWriting";
import {getMaterialAndDownloadMultimedia} from "./startingAndClossingLogic";
import {inDevelopment} from "./frontendSpecificFunctions";
import {request, sendFakeShot} from "./Requests";
import {APICommand} from "./shared-modules/commandApi";
import {beSureItIsPlainObject} from "./shared-modules/funkcje";
import {SceneTopic} from "./SceneTopic";


export const StartingButtonView = ({user, logOut}: { user: User, logOut: () => void }) => {

    const [rankList, setRankList] = useState([] as RankingRecord[])
    const [clicked, setClicked] = useState(false)
    const [topics, setTopics] = useState([] as SceneTopic[])

    useEffect(() => {
        const start = Date.now()
        getMaterialAndDownloadMultimedia()
            .then(resp => {
                setTopics(resp)
                sendFakeShot(`material downloaded. It took ${Date.now() - start}`).then()
            })
        request(APICommand.GetRankList, null)
            .then(resp => setRankList(resp.data as RankingRecord[]))
    }, [])

    function produceRankFields() {
        const items = []
        for (let index = 0; index < rankList.length; index++) {
            const rank = beSureItIsPlainObject(rankList[index], "rank is not a plain object")
            // const color = rank.isReal ? '#444' : '#777'
            const fontWeight = rank.isReal ? 'bold' : 'normal'
            const spacer = index + 1 < 10 ? ' . . . ' : " . . "
            if (items.length < 10 || rank.isReal)
                items.push(
                    <li key={index} style={{color: "#666", fontWeight}}>
                        {index + 1 + spacer + rank.nick}
                    </li>
                )
        }
        return items
    }

    const buttonPart = (topics.length === 0)
        ? <H2>Downloading material.Please wait...</H2>
        : <Button style={{background: "green"}} onClick={() => setClicked(true)}>START</Button>


    if (!clicked) {
        return (
            <Vertically>

                {buttonPart}
                <EnglishDoneWriting logOut={logOut}/>

                <div style={{marginTop: '30px'}}>
                    <H3>YOUR POSITION IN YOUR GROUP</H3>
                    <ul style={{listStyleType: 'none', padding: 0}}>
                        {produceRankFields()}
                    </ul>
                </div>

                {
                    inDevelopment(user) && topics.length > 0 &&
                    <p style={{height: "50rem", background: "lightgreen", margin: 0}}
                       onClick={() => setClicked(true)}>start</p>
                }
            </Vertically>
        )
    } else {
        return <LearningView user={user} topics={topics}/>
    }
}






