import styled from "styled-components";


export const PictureCardStyle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-top
    justify-content: space-evenly;
    max-width: 30%;
    border-radius: 5px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
    transition: 0.5s ease;
    cursor: pointer;
    margin: 10px;
    @media only screen and (orientation: portrait) {
        max-width: 100%;
        flex-direction: row;
        justify-content: normal;
        border-radius: 0;
        box-shadow: none;
        margin: 1px;
    }
`

export const PictureOnCardStyle = styled.img`
    max-width: 100%;
    pointer-events: none;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    @media screen and (orientation: portrait) {
        max-width: 50%;
        border-radius: 0;
    }
`
//
// export const PictureRestyledForCard = () =>
//     <img src={} style={{maxWidth: "100%", pointerEvents: "none", borderTopLeftRadius: "5px",borderTopRightRadius: "5px"}}>
//
//     </img>
//
//   // @media screen and (orientation: portrait) {
//   //   max-width: 50%;
//   //   border-radius: 0;


export const OrientationSavvyCaption = styled.div`
    font-size: 1.5rem;
    text-align: center;
    margin: 1rem;
    color: gray;
    @media screen and (orientation: portrait) {
        text-align: left;
        margin-left: 1rem;
    }
`

export const BigWriting = styled.div`
    height: 2.2rem;
    font-size: 2rem;
    margin: 1rem;
    color: gray;
    @media screen and (orientation: portrait) {
        height: 1.6rem;
        font-size: 1.5rem;
    }
`


export const ElasticCardStyleStyle = styled.div`
    flex-grow: 1;
    border-radius: 5px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
    transition: 0.5s ease;
    margin: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    white-space: pre-wrap;
    align-items: center;
    font-size: 2rem;
    @media only screen and (orientation: portrait) {
        border-radius: 0;
        margin: 0;
        box-shadow: none;
        border: 1px solid grey;
    }
`

export const Button = styled.button`
    //display: inline-block;
    border-radius: 3px;
    padding: 0.5rem 0;
    margin: 0.2rem 0.2rem;
    height: 3rem;
    width: 6rem;
    background: darkorange;
    color: white;
    border: 2px solid white;
    box-shadow: 2px 2px 5px rgba(0, 0, 100, 0.4);

    &:hover {
        border: 2px solid red;
    }
`


export const FaceImgStyle = styled.img`
    width: 8rem;
    align-self: center;
`

export const ProgressBarFill = styled.div<{ color: string; width: number }>`
    display: inline-block;
    height: 5px;
    transition: width 200ms ease-in-out;
    background-color: ${props => props.color};
    width: ${props => props.width}%;
`

export const HeaderStyle = styled.section<{ backgroundColor: string }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: 2px 2px 5px rgba(0, 0, 100, 0.4);
    background-color: ${props => props.backgroundColor};
    height: 0.3rem;
    padding: 1em;
    @media (pointer: none), (pointer: coarse) {
        display: none;
    }
`

export const Container = styled.div`
    padding-right: 15%;
    padding-left: 15%;
    margin: 10px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    @media (pointer: none), (pointer: coarse), (orientation: portrait) {
        padding-right: 0;
        padding-left: 0;
        margin: 0;
    }
`


export const BottomStyle = styled.div`
    position: fixed;
    width: 50%;
    bottom: 10px;
    text-align: left;
`

export const AlwaysHorizontalFlex = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
`

export const PanelsFlex = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    @media only screen and (orientation: portrait) {
        flex-direction: column;
    }
`

export const CenteredFlexStyle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center; /*centers items on the line (the x-axis by default)*/
    align-items: center; /*centers items on the cross-axis (y by default)*/
`


export const Horizontally = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`

export const Vertically = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const H1 = styled.h1`
    color: grey;
    font-weight: normal;
    font-family: serif;
`


export const H2 = styled.h2`
    color: grey;
    font-weight: normal;
`

export const P = styled.p`
    color: grey;
    font-weight: normal;
`


export const H3 = styled.h3`
    color: grey;
    font-weight: normal;
`

export const H4 = styled.h4`
    color: grey;
    font-weight: normal;
`

export const H5 = styled.h5`
    color: grey;
    font-weight: normal;
`

