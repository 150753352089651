export class NullAudio {
    async play(): Promise<void> {
    }
}

export const soundEffects = {
    laugh: new Audio("sound-effects/laugh.mp3"),
    cash: new Audio("sound-effects/cash.mp3"),
    fail: new Audio("sound-effects/fail.mp3"),
    accessDenied: new Audio("sound-effects/access-denied.mp3"),
    lotteryBeginning: new Audio("sound-effects/lottery-beginning.mp3"),
}


export const freshPlayAudio = (audio: HTMLAudioElement) => {
    audio.currentTime = 0
    audio.play().then()
}