import {ProgressBarFill} from "./StyledComponents";


export function ProgressBar({length, color}: { length: number; color: string }) {


    return (
        <div style={{margin: "1pix"}}>
            <ProgressBarFill width={Math.min(Math.abs(length) * 100, 100)}
                             color={(length >= 0) ? color : 'red'}/>
        </div>
    )
}