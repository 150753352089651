import {useState} from "react";

export const SummaryCheatSurface= () => {

    const [clicked, setClicked] = useState(0)

    const clickOnce = () => {
        if (Date.now() - clicked > 5_000 ) {
            setClicked(Date.now())
            window.location.reload()
        }
    }

    return (
        <p style={{height: "80rem", background: "orangered", margin: 0}}
           onClick={clickOnce}>
            CLICK TO RELOAD
        </p>
    )
}

