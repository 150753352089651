import {ReactElement, useEffect, useState} from "react";
import {Navbar} from "./Navbar";
import {LoginView} from "./LoginView";
import {Container} from "./StyledComponents";
import {ChartsView} from "./ChartsView";
import {request} from "./Requests";
import {StartingButtonView} from "./StartingButtonView";
import {User} from "./shared-modules/sharedTypes";
import {APICommand} from "./shared-modules/commandApi";
import {beSureItIsPlainObject} from "./shared-modules/funkcje";

type ViewSelector = "not-validated" | "logging-view" | "chart-view" | "start-button-view"


const App = () => {
    const [selector, setSelector] = useState("not-validated" as ViewSelector)
    const [user, setUser] = useState(null as User | null)


    async function logOutUser(): Promise<void> {
        const responseDTO = await request(APICommand.LogUserOut, null)
        if (responseDTO.success) {
            setSelector("logging-view")
        }
    }

    async function showCharts(): Promise<void> {
        return setSelector("chart-view")
    }


    function onUseEffect(): void {
        document.body.style.backgroundColor = "floralwhite"
        request(APICommand.GetLoggedUser, null).then(responseDTO => {
                const user = responseDTO.data as User
                if (responseDTO.success) {
                    setUser(user)
                    // callHomeLoop().then()
                    setSelector("start-button-view")
                } else {
                    setSelector("logging-view")
                }
            }
        )
    }

    useEffect(onUseEffect, [])


    let view: ReactElement;
    switch (selector) {
        case "not-validated":
            view = <p>...</p>;
            break;
        case "logging-view":
            view = <LoginView callback={onUseEffect} showCharts={showCharts}/>
            break;
        case "chart-view":
            view = <ChartsView callback={onUseEffect}/>;
            break;
        case "start-button-view":
            view = <StartingButtonView user={beSureItIsPlainObject(user, "user is not a plain object")} logOut={logOutUser}/>;
            break;
        default:
            view = <h2>You shouldn't see this!</h2>;
    }


    return (
        <>
            <Navbar color={user ? user.color : "gray"}/>
            <Container>
                {view}
            </Container>
        </>
    )
}


export default App