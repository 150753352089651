import {beSureItIsAnyObject, beSureItIsString, firstEl, nullCheck} from "./shared-modules/funkcje";
import {request, sendLogToServer} from "./Requests";
import {APICommand, PartialLogRecord} from "./shared-modules/commandApi";
import html2canvas from "html2canvas";


let QUEUE: PartialLogRecord[] = []
let PENDING = false


export async function screenShot(userName: string, title: string, textOnPicture: string, textColor: string): Promise<void> {
    let screenshot: PartialLogRecord
    if (QUEUE.length > 2) {
        sendLogToServer("ERROR", "screenshot-jammed")
            .then()
            .catch(e => console.error(`something wrong with logMsgAsPic inside screenShot`, e.message))
    } else {
        screenshot = await makeScreenShot(userName, title, textOnPicture, textColor)
        QUEUE.push(screenshot)
    }
    if (!PENDING) {
        makeRequests().then()
    }
}


async function makeRequests(): Promise<void> {
    PENDING = true
    while (QUEUE.length > 0) {
        const firstScreenshot = firstEl(QUEUE)
        await request(APICommand.Log, firstScreenshot)
        QUEUE = QUEUE.slice(1, 1000000)
    }
    PENDING = false
}

async function makeScreenShot(userName: string, filenamePart: string, textOnPicture: string, textColor: string): Promise<PartialLogRecord> {
    const canvas = await html2canvas(document.body, {scale: 0.5})
    const ctx = nullCheck(canvas.getContext("2d"))
    ctx.font = "30px Arial";
    ctx.fillStyle = textColor;
    ctx.fillText(textOnPicture, 50, canvas.height - 130)
    const dataURL = canvas.toDataURL(`image/webp`, 0.3)
    const b64data = dataURL.split("base64,")[1]
    return {
        level: "NOTICE",
        loggerName: "browser",
        message: `${filenamePart}.webp`,
        screenshotData: beSureItIsString(b64data, "b64data is not a string"),
    }
}













