import {useEffect} from "react";
import {sendFakeShot} from "./Requests";
import {H1, H3, P} from "./StyledComponents";
import {SummaryCheatSurface} from "./SummaryCheatSurface";
import {screenShot} from "./screenshoting";
import {inDevelopment} from "./frontendSpecificFunctions";
import {User} from "./shared-modules/sharedTypes";
import {SceneTopic} from "./SceneTopic";


export function LessonSummaryView({started, topics, user}: {
    started: number,
    topics: SceneTopic[],
    user: User
}) {
    const totalTimeOfLearning = Date.now() - started

    let hits = 0
    let errors = 0

    for (const scene of topics.flatMap(t => t.pairs)) {
        hits += scene.hits
        errors += scene.errors
    }


    const clicks = hits + errors
    const totalTime = new Date(totalTimeOfLearning).toISOString().slice(14, -2)
    const timeForQuestion = (totalTimeOfLearning / 1000 / hits).toFixed(2)
    const timeForClick = (totalTimeOfLearning / 1000 / clicks).toFixed(2)
    const accuracy = (hits / clicks * 100).toFixed(2)

    const summaryText =
        `   klikow: ${clicks}\n` +
        `   calkowityCzas: ${totalTime}\n` +
        `   czasNaPytanie: ${timeForQuestion}\n` +
        `   czasNaClick: ${timeForClick}\n` +
        `   accuracy: ${accuracy}`


    useEffect(
        () => {
            screenShot(user.username, "SUMMARY", "", 'blue').then()
            sendFakeShot(summaryText).then()
        }
        , [summaryText, user.username]
    )


    return (
        <>
            <div style={{textAlign: "center", color: "white"}}>
                <H1>Well done!</H1>
                <H3>{`Average time per question: ${timeForQuestion} second`}</H3>
                <P>{`Errors: ${errors}`}</P>
                <P>{`Accuracy: ${accuracy}%`}</P>
            </div>
            {inDevelopment(user) && <SummaryCheatSurface/>}
        </>
    )
}

