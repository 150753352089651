import {HeaderStyle} from "./StyledComponents";


export const Navbar = ({color}: { color: string }) => {
    return (
        <HeaderStyle backgroundColor={color}>
            <a style={{textDecoration: "none", color: "white"}} href="https://www.rosettastone.com/">
                Rosetta Stone v 1.0.3
            </a>
        </HeaderStyle>
    )

}