import chalk from "chalk";
import {User, UserRole} from "./shared-modules/sharedTypes";
import {Scene} from "./Scene";


export const isTestMode = (user: User) => process.env.NODE_ENV === "development" || user.role === UserRole.Tester


export const beSureInDev = (wyr: number | string | boolean, errMessage = "Assertion Error") => {
    if (!wyr && process.env.NODE_ENV === "development") {
        alert(errMessage)
        throw new Error(errMessage)
    }
}

export const devFreeze = <T>(object: T[]) => {
    if (process.env.NODE_ENV === "development") {
        Object.freeze(object)
    }
    return object
}

export const doIfDev = (funcToRun: () => any): void => {
    if (process.env.NODE_ENV === "development") {
        return funcToRun()
    }
}

export function printNormal(...args: any[]) {
    console.log(chalk(...args))
}

export function printBold(...args: any[]) {
    console.log(chalk.bold.black(...args))
}

export function printAlert(...args: any[]) {
    console.log(chalk.bgYellowBright.bold(...args))
}

export function printError(...args: any[]) {
    console.log(chalk.bgBlack.bold.yellow(["ERROR", ...args].join(" ")))
}


export function inDevelopment(user: User) {
    return process.env.NODE_ENV === "development" || user.role === "tester"
}

export function withAddedTo(scene: Scene, prop: "hits" | "errors"): Scene {
    return {...scene, [prop]: scene[prop] + 1}
}