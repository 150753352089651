import {Horizontally} from "./StyledComponents";
import {random} from "./shared-modules/funkcje";
import {Scene} from "./Scene";

export const LearningCheatSurface = ({scene, checkAnswer}: {
    scene: Scene,
    checkAnswer: (s: string) => void | Promise<void>
}) => {


    function makeSurface(background: string, cheatFun: () => void | Promise<void>) {
        return (
            <p style={{height: "40rem", background, margin: 0}} onClick={cheatFun}>
                --------------------------------------------------------------<br/>
                isNew: {String(scene.comment)}<br/>
                weight: {scene.weight}<br/>
                errors: {scene.errors}<br/>
                hits: {scene.hits}<br/>
            </p>

        )
    }


    return (
        <Horizontally>
            {makeSurface("lightgreen", () => checkAnswer(scene.match))}
            {makeSurface("yellow", () => checkAnswer(random.choice(scene.answers, scene.seed + scene.errors).string))}
        </Horizontally>
    )
}

