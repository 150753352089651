import {firstEl} from "./shared-modules/funkcje";
import {SceneTopic} from "./SceneTopic";


export const makeMaterialDescription = (topics: SceneTopic[]) => {
    const legend = "swi|pow|res|all|sredOczPow|sumOczResz|pierwszy element  \n"
    const line__ = "------------------------------------------------------------------\n"
    const descriptionOfFresh: string[] = topics.map(topic => describeTopic(topic))
    return legend + line__ + descriptionOfFresh.join("\n")
}

const describeTopic = (topic: SceneTopic): string => {
    const narrowColumn = 3
    // const wideColumn = 10


    return [
        // spacesBefore(narrowColumn, fresh.length),
        // spacesBefore(narrowColumn, repetition.length),
        // spacesBefore(narrowColumn, ordinary.length),
        spacesBefore(topic.pairs.length, narrowColumn),
        // spacesBefore(round(topic.waitForRepetitionSum , 3), wideColumn),
        // spacesBefore(waitSum, wideColumn),
        spacesAfter(firstEl(topic.pairs).asked.string, 25),
    ].join("|")
}


function spacesToComplete(content: string | number, shouldBe: number): string {
    return "                                    ".slice(0, shouldBe - content.toString().length)
}

function spacesBefore(ile: number, word: string | number): string {
    return spacesToComplete(word, ile) + word
}

function spacesAfter(word: string | number, ile: number): string {
    return word + spacesToComplete(word, ile)
}


