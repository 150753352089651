import {LangName, RichWord} from "./shared-modules/materialTypes";

export type WordWithBlob = {
    readonly langName: LangName
    readonly string: string,
    readonly imgBlob?: Blob
}



export async function makeWordWithBlob(richWord: RichWord): Promise<WordWithBlob> {
    const {picFilename, ...rest} = richWord
    return {
        imgBlob: picFilename !== undefined ? await fetchBlob(`/images/${picFilename}`) : undefined,
        ...rest
    }
}

export async function fetchBlob(url: string): Promise<Blob> {
    const response = await fetch(url)
    if (!response.ok) {
        throw new Error(`Cannot fetch url: "${url}". Status: "${response.status}"`);
    }
    return response.blob();
}