import {CompletedLesson} from "./shared-modules/sharedTypes";

type ChartType = 'area' | 'bar' | "line" | "column"



export type ChartOptions = {
    chart?: {
        type: ChartType
    },
    title: {
        text: string
    },
    plotOptions: {
        column: {
            stacking: string,
        }
    },
    series: {
        type?: ChartType,
        name: string,
        data: (number | null)[]
    }[]
}


export function makeChartOptions(data: CompletedLesson[], range: number): ChartOptions {
    return {
        chart: {
            type: "column"
        },
        title: {
            text: "" // potrzeba "" zeby to kurestwo nie posalo "Chart title"
        },
        plotOptions: {
            column: {
                stacking: 'normal',
            }
        },
        series: [
            {
                name: "good answers",
                data: data.map(x => x.trafienia).slice(-range,)
            },
            {
                name: "bad answers",
                data: data.map(x => x.pudla).slice(-range,)
            }
        ]
    }
}



