import {AudioObject} from "./audioObject";
import {fetchBlob, makeWordWithBlob, WordWithBlob} from "./WordWithBlob";
import {PairWithStats, TopicType, TopicWithStats} from "./shared-modules/materialTypes";
import {random} from "./shared-modules/funkcje";
import {isSimilarToOneOf} from "./findConflict";
import {makeQuestionsBySimilarity} from "./makeQuestionsBySimilarity";


export type Scene = {
    readonly type: TopicType
    readonly key: string
    readonly seed: number
    readonly comment: string
    readonly weight: number
    readonly match: string
    readonly hits: number,
    readonly errors: number,
    readonly audioObject: AudioObject
    readonly asked: WordWithBlob,
    readonly answers: WordWithBlob[]
}

export async function makeScene(pair: PairWithStats, topic: TopicWithStats): Promise<Scene> {

    let key = pair.id
    let shownWord = pair.shownWord
    let match = pair.goodAnswer.string
    let answerWords = [pair.goodAnswer]


    const woutIncomplete = topic.pairs.filter(p => p !== pair)
    const candidates = random.shuffled(woutIncomplete, pair.seed)

    function randomBads(howMuch: number): PairWithStats[] {
        const bads = []
        for (const candidate of random.shuffled(candidates, pair.seed)) {
            if (bads.length < howMuch && !isSimilarToOneOf(candidate, [pair, ...bads])) {
                bads.push(candidate)
            }
        }
        return bads
    }


    const topicShouldBeReversed = random.bool(shownWord.picFilename === undefined ? 0.6 : 0.2, topic.seed)
    if (topic.type === "matched") {
        const bads = makeQuestionsBySimilarity(pair, candidates).map(b => b.goodAnswer)
        answerWords.push(...bads)
    } else if (topic.type === "reversible" && topicShouldBeReversed && 2 > 15) {
        shownWord = pair.goodAnswer
        match = pair.shownWord.string
        const howMuchToMake = 1
        answerWords = [pair.shownWord, ...randomBads(howMuchToMake).map(b => b.shownWord)]
    } else if (topic.type === "reversible" || topic.type === "basic") {
        const howMuchToMake = pair.goodAnswer.picFilename === undefined ? 1 : 2
        answerWords.push(...randomBads(howMuchToMake).map(p => p.goodAnswer))
    } else {
        throw Error("I shouldn't be here")
    }


    const audioObject = new AudioObject(await fetchBlob(`/dzwieki/${shownWord.soundFilename}`))
    const asked = await makeWordWithBlob(shownWord)
    const answers = []
    for (const word of answerWords) {
        answers.push(await makeWordWithBlob(word))
    }


    return {
        key,
        type: topic.type,
        seed: pair.seed,
        comment: pair.comment,
        weight: pair.weight,
        match,
        hits: 0,
        errors: 0,
        audioObject,
        asked,
        answers,
    }
}



