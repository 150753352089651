import {useEffect, useState} from "react";
import {Scene} from "./Scene";
import {firstEl, sleep} from "./shared-modules/funkcje";
import {User} from "./shared-modules/sharedTypes";
import {ProgressBar} from "./ProgressBar";
import {SceneView} from "./SceneView";
import {H1, H2, Vertically} from "./StyledComponents";
import {soundEffects} from "./soundEffects";
import {sendFakeShot} from "./Requests";
import {SceneTopic} from "./SceneTopic";
import {inDevelopment, withAddedTo} from "./frontendSpecificFunctions";

type ViewName = "learning" | "tooSlow"


type Stage = {
    readonly started: number,
    readonly viewName: ViewName,
    readonly pairs: Scene[],
    readonly score: number,
    readonly hits: number,
    readonly errors: number,
}


export function TopicView(
    {topic, user, nextTopic}: {
        topic: SceneTopic,
        user: User,
        nextTopic: (t: SceneTopic, a: number, s: number) => void
    }
) {

    const [stageState, setStageState] = useState(makeStageState(topic))

    useEffect(() => {
        firstEl(topic.pairs).audioObject.play().then()
        setStageState(makeStageState(topic))
    }, [topic])


    function makeStageState(topic: SceneTopic): Stage {
        return {
            started: Date.now(),
            viewName: "learning",
            pairs: topic.pairs,
            score: 0,
            hits: 0,
            errors: 0,
        }
    }

    const currentScene = firstEl(stageState.pairs)

    async function whenHit(scene: Scene) {
        const score = stageState.score + 1
        currentScene.audioObject.pause()
        const pairs = [...stageState.pairs.slice(1,), withAddedTo(scene, "hits")]
        const stateAfterHit: Stage = {...stageState, pairs, hits: stageState.hits + 1, score}
        if (score < stageState.pairs.length) {
            return setStageState(stateAfterHit)
        } else {
            return nextTopic({
                ...topic,
                pairs
            }, stateAfterHit.errors / stateAfterHit.hits, await computeScoreForCallback(stateAfterHit))
        }
    }

    function subtractPoints() {
        let score = stageState.score
        if (stageState.errors < stageState.pairs.length / 7) {
            score -= 0
        } else if (stageState.errors < stageState.pairs.length || stageState.score < 0) {
            score -= 1
        } else {
            score -= 2
        }
        return setStageState({...stageState, score, errors: stageState.errors + 1})
    }

    function putSceneInTheMiddle(scene: Scene) {
        const topic = [...stageState.pairs.slice(1, 3), withAddedTo(scene, "errors"), ...stageState.pairs.slice(3,)]
        return setStageState({...stageState, pairs: topic})
    }

    // accuracy    if (stageSt.errors / stageSt.hits < 0.25) {

    async function computeScoreForCallback(topicSt: Stage): Promise<number> {
        const timeItTook = Date.now() - topicSt.started
        const numOfClicks = topicSt.hits + topicSt.errors
        if (timeItTook / numOfClicks < 5000) {
            return topicSt.pairs.length
        } else {
            soundEffects.accessDenied.play().then()
            setStageState({...topicSt, viewName: "tooSlow"})
            await sleep(2000)
            sendFakeShot("topic-time-out was shown")
                .then()
                .catch(e => console.error(`something wrong with logMsgAsPic inside screenShot`, e.message))
            return 0
        }
    }


    return (
        <>
            {
                stageState.viewName === "learning" &&
                <>
                    {inDevelopment(user) && <p> {topic.title}<br/></p>}
                    <ProgressBar length={stageState.score / stageState.pairs.length} color="green"/>
                    <SceneView scene={firstEl(stageState.pairs)}
                               user={user}
                               whenHit={whenHit}
                               whenError={subtractPoints}
                               afterError={putSceneInTheMiddle}/>
                </>
            }
            {
                stageState.viewName === "tooSlow" &&
                <Vertically>
                    <H1 style={{color: "red"}}>I was pretty slow!</H1>
                    <H2 style={{color: "red"}}>No points this time.</H2>
                </Vertically>
            }
        </>
    )
}


