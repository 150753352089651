import React, {MouseEvent, useState} from "react";
import {Button, H2} from "./StyledComponents";
import {request} from "./Requests";
import {APICommand} from "./shared-modules/commandApi";


export const LoginView = ({callback, showCharts}: { callback: () => void, showCharts: () => void }) => {

    const [msgOnScreen, setMsgOnScreen] = useState("Log in please.")
    const [userNameToBe, setUserNameToBe] = useState("")
    const [passwordToBe, setPasswordToBe] = useState("")

    const onButtonClick = async (event: MouseEvent) => {
        if (userNameToBe === "charts") {
            return showCharts()
        }
        try {
            const responseDTO = await request(APICommand.LogUserIn, {userName: userNameToBe, password: passwordToBe})
            if (responseDTO.success) {
                setUserNameToBe("")
                setPasswordToBe("")
                return callback()
            } else {
                setMsgOnScreen("EITHER LOGIN OR PASSWORD WAS INCORRECT")
            }
        } catch {
            alert("Either username or password are wrong")
        }
    }


    return (
        <form onSubmit={e => e.preventDefault()}>
            <H2>{msgOnScreen}</H2>

            <label>
                Username:
                <input type="text"
                       name="username"
                       autoComplete="on"
                       value={userNameToBe}
                       onChange={(event) => setUserNameToBe(event.target.value)}/>
            </label>
            <br/><br/>
            <label>
                Password:
                <input type="password"
                       name="password"
                       autoComplete="on"
                       value={passwordToBe}
                       onChange={(event) => setPasswordToBe(event.target.value)}/>
            </label>
            <br/><br/>
            <Button onClick={onButtonClick}>Login</Button>

        </form> // needed for browsers to consider page as logging page
    )
}

