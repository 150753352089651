import {
    PictureCardStyle, ElasticCardStyleStyle, OrientationSavvyCaption, H5, PictureOnCardStyle
} from "./StyledComponents";
import {WordWithBlob} from "./WordWithBlob";


export const AnswerCard = ({content, callback, bg}: {
    content: WordWithBlob,
    callback: () => void,
    bg: string
}) => {

    const {string, imgBlob} = content


    if (imgBlob) {
        return (
            <PictureCardStyle style={{background: bg}} onClick={callback}>
                <PictureOnCardStyle src={URL.createObjectURL(imgBlob)} alt={"Answer card"}/>
                <OrientationSavvyCaption>
                    {string}
                </OrientationSavvyCaption>
            </PictureCardStyle>
        )
    } else {
        return (
            <ElasticCardStyleStyle style={{background: bg}} onClick={callback}>
                <H5>
                    {string.replaceAll(", ", ",\n")
                        .replaceAll("(", "\n(")}
                </H5>
            </ElasticCardStyleStyle>
        )
    }
}

