import {useEffect, useState} from "react";
import {Button, Container} from "./StyledComponents";
import {request} from "./Requests";
import {CompletedLesson, UserResults} from "./shared-modules/sharedTypes";
import {makeChartOptions} from "./Charting";
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import {elementAt} from "./shared-modules/funkcje";
import {APICommand} from "./shared-modules/commandApi";


export const ChartsView = ({callback}: { callback: () => void }) => {

    const [usersResults, setUsersResults] = useState([] as UserResults[])
    const [scope, setScope] = useState(30)

    useEffect(() => {
        request(APICommand.GetResultsOfUsers, null).then(dto => {
            const results = dto.data as UserResults[]
            setUsersResults(results)
        })
    }, [])

    async function logoutAdmin() {
        await request(APICommand.LogUserOut, null)
        return callback()
    }

    function countDaysWithoutLesson(collection: CompletedLesson[]): number {
        let omissions = 0
        let i = 1
        while (true) {
            if (elementAt(collection, collection.length - i).trafienia === 0) {
                omissions++
            } else {
                return omissions
            }
            i++
        }


    }


    return (
        <Container>
            {

                usersResults
                    .map(userResults => {
                            const {userName, data} = userResults
                            let titleColor = "#ccc"
                            const undone = countDaysWithoutLesson(data)
                            if (undone === 0) {
                                titleColor = "lightgreen"
                            } else if (undone > 3) {
                                titleColor = "red"
                            }

                            const options = makeChartOptions(data, scope)
                            return (
                                <div style={{background: titleColor, textAlign: "center"}}>
                                    <h2>
                                        {userName}<br/>
                                        <small>No lesson done in last {countDaysWithoutLesson(data)} days.</small>
                                    </h2>
                                    <HighchartsReact highcharts={Highcharts} options={options}/>
                                </div>)
                        }
                    )
            }

            {
                [30, 90, 180, 365, 730, 1095].map(days =>
                    <Button
                        style={{background: "#357caf"}}
                        onClick={() => setScope(days)}>
                        {days} days
                    </Button>)
            }
            <Button style={{background: "red"}} onClick={logoutAdmin}>GO BACK</Button>
        </Container>
    )
}
